import Rails from "@rails/ujs";
import "./site_controllers"
Rails.start();

document.addEventListener("DOMContentLoaded", function () {
    // Header on scroll change Background
    var prevScrollPos = window.pageYOffset;

    window.addEventListener('scroll', function () {
        var currentScrollPos = window.pageYOffset;
        var header = document.querySelector('header');

        if (currentScrollPos == 0) {
            header.classList.remove('hide');
            header.classList.remove('bg-dark');
        } else {
            if (prevScrollPos > currentScrollPos) { // scroll up
                header.classList.remove('hide');
                header.classList.add('bg-dark');
            } else if (currentScrollPos > 118) {  // scroll down
                header.classList.add('hide');
            } 
        }
        prevScrollPos = currentScrollPos;
    });


    // Main Menu
    document.querySelector('.nav-toggle').addEventListener('click', function (e) {
        document.querySelector('.nav').classList.toggle('open-nav');
    });

    // Sub Menu active
    document.querySelectorAll('.has-child').forEach(menu => {
        menu.querySelectorAll('.sub-menu li').forEach(el => {
            if (el.classList.contains('active')) {
                menu.classList.add('active')
            }
        });
    });

    // Comprehensive Section
    let comprehensive_sec = document.querySelector('.comprehensive-sec')

    if (comprehensive_sec) {

        let allImages = comprehensive_sec.querySelectorAll('.imgs img')
        let allItems = comprehensive_sec.querySelectorAll('.list .item')

        let firstImage = comprehensive_sec.querySelector('.imgs img')
        let firstItem = comprehensive_sec.querySelector('.list .item')

        firstImage.classList.add('active')
        firstItem.classList.add('active')


        setInterval(function () {
            let activeItem = comprehensive_sec.querySelector('.list .item.active')
            let activeImage = comprehensive_sec.querySelector('.imgs img.active')
            let nextActiveItemId = parseInt(activeItem.dataset.id) + 1

            if (nextActiveItemId <= allItems.length) {
                activeItem.classList.remove('active')
                activeImage.classList.remove('active')
                comprehensive_sec.querySelector('.list .item[data-id="' + nextActiveItemId + '"]').classList.add('active')
                comprehensive_sec.querySelector('.imgs img[data-id="' + nextActiveItemId + '"]').classList.add('active')
            } else {
                activeItem.classList.remove('active')
                activeImage.classList.remove('active')
                firstImage.classList.add('active')
                firstItem.classList.add('active')
            }

        }, 5100);
    }

    // All Sliders
    if (document.querySelector('.testimonial-slider')) {
        var swiper = new Swiper(".testimonial-slider", {
            slidesPerView: 1,
            spaceBetween: 20,
            centeredSlides: true,
            grabCursor: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                640: {
                    slidesPerView: 1,
                },
                768: {
                    slidesPerView: 1,
                },
                1024: {
                    slidesPerView: 2,
                },
            },
        });
    }

    if (document.querySelector('.kidscase-slider')) {
        var swiper = new Swiper(".kidscase-slider", { 
            slidesPerView: 1,  
            spaceBetween: 20, 
            grabCursor: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }, 
        });
    }

    if (document.querySelector('.articles-slider')) {
        var swiper = new Swiper(".articles-slider", {
            slidesPerView: 1,
            spaceBetween: 20,
            centeredSlides: true,
            grabCursor: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                640: {
                    slidesPerView: 1,
                },
                768: {
                    slidesPerView: 1,
                },
                1024: {
                    slidesPerView: 4,
                },
            },
        });
    }

    // Price Switcher
    let priceSwitcher = document.querySelector('.pricing-sec')
    if (priceSwitcher && priceSwitcher.querySelector('.switcher')) {
        priceSwitcher.querySelector('.switcher').addEventListener('click', function () {
            priceSwitcher.querySelector('.switcher').classList.toggle('active')
            if (priceSwitcher.querySelector('.switcher').classList.contains('active')) {
                priceSwitcher.querySelector('.annualy').classList.add('active')
                priceSwitcher.querySelector('.monthly').classList.remove('active')

                priceSwitcher.querySelector('.annualy-pricing').classList.add('active')
                priceSwitcher.querySelector('.monthly-pricing').classList.remove('active')
            } else {
                priceSwitcher.querySelector('.annualy').classList.remove('active')
                priceSwitcher.querySelector('.monthly').classList.add('active')
                priceSwitcher.querySelector('.annualy-pricing').classList.remove('active')
                priceSwitcher.querySelector('.monthly-pricing').classList.add('active')
            }
        })
    }

    // Animation Text
    if (document.querySelector('.stella-text')) {
        var i = 0;
        var x = 0;
        var speed = 50;
        var txt_1 = document.querySelector('.stella-text p').textContent;
        // var txt_2 = document.querySelector('.stella-text p:nth-child(2)').textContent;
        document.querySelector('.stella-text p').innerHTML = ''
        // document.querySelector('.stella-text p:nth-child(2)').innerHTML = ''

        function typeWriter() {
            if (i < txt_1.length) {
                document.querySelector('.stella-text p').innerHTML += txt_1.charAt(i);
                i++;
                setTimeout(typeWriter, speed);
            }
        }
        // function typeWriter2() {
        //     if (x < txt_2.length) {
        //         document.querySelector('.stella-text p:nth-child(2)').innerHTML += txt_2.charAt(x);
        //         x++;
        //         setTimeout(typeWriter2, speed);
        //     }
        // }
        typeWriter()
        // setInterval(function () {
        //     typeWriter2()
        // }, 3300);
    }

    if (document.querySelector('.notice')) {
        console.log("YES");
        var allMsgs = document.querySelectorAll('.notice')
        allMsgs.forEach(msg => {
            console.log(msg);
            msg.querySelector('.close-btn').addEventListener('click', function (e) {
                console.log('clicked');
                msg.classList.add('hide');
            })
        });
    }

    var right = document.getElementsByClassName("right");
    var si = right.length - 1; // Start from the last index
    var z = 1;

    // Update button visibility based on the current slide index
    function updateButtons() {
        document.getElementById("prevButton").style.display = (si >= 4) ? "none" : "inline-block";
        document.getElementById("nextButton").style.display = (si === 0) ? "none" : "inline-block";
    }

    // Initialize the buttons visibility on load
    updateButtons();

    function turnRight() {
    if(si > 0) {
            right[si].classList.add("flip");
            z++;
            right[si].style.zIndex = z;
            si--;
            
        } else {
            // Reset to the last pair
            si = right.length - 1;
            for (var i = 0; i < right.length; i++) {
                right[i].classList.remove("flip");
                right[i].style.zIndex = "auto";
            }
            z = 1;
        }
        updateButtons();
    }

    function turnLefty() {
        if (si < right.length - 1) {
            right[si+1].classList.remove("flip");
            right[si+1].style.zIndex = z++;
            si++;

        } else {
            // Reset to the first pair
            si = 0;
            for (var i = right.length - 1; i >= 0; i--) {
                right[i].classList.add("flip");
                right[i].style.zIndex = right.length - i;
            }
        }
        updateButtons();
    }

    document.getElementById("prevButton").addEventListener("click", turnLefty);
    document.getElementById("nextButton").addEventListener("click", turnRight);

    // for the first auto flip
    turnRight();

});