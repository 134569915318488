import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["popup"]

    openModal(e) {
        var popup = document.querySelector('.modal')
        popup.classList.add('open')
    }

    closeModal() {
        this.popupTarget.classList.remove('open')
        var videos = document.querySelectorAll('iframe, video');
        Array.prototype.forEach.call(videos, function (video) {
            if (video.tagName.toLowerCase() === 'video') {
                video.pause();
            } else {
                var src = video.src;
                video.src = src;
            }
        });
    }

    close(e) {
        if (e.target == this.popupTarget) {
            this.popupTarget.classList.remove('open')
        }
    }
}